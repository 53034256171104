<template>
  <svg
    viewBox="0 0 20 20">
    <path
      d="M18,0 L2,0 C0.9,0 0,0.9 0,2 L0,18 C0,19.1 0.9,20 2,20 L18,20 C19.1,20 20,19.1 20,18 L20,2
      C20,0.9 19.1,0 18,0 L18,0 Z M6,17 L3,17 L3,8 L6,8 L6,17 L6,17 Z M4.5,6.3 C3.5,6.3 2.7,5.5
      2.7,4.5 C2.7,3.5 3.5,2.7 4.5,2.7 C5.5,2.7 6.3,3.5 6.3,4.5 C6.3,5.5 5.5,6.3 4.5,6.3 L4.5,6.3
      Z M17,17 L14,17 L14,11.7 C14,10.9 13.3,10.2 12.5,10.2 C11.7,10.2 11,10.9 11,11.7 L11,17
      L8,17 L8,8 L11,8 L11,9.2 C11.5,8.4 12.6,7.8 13.5,7.8 C15.4,7.8 17,9.4 17,11.3 L17,17
      L17,17 Z">
    </path>
  </svg>
</template>
